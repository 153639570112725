<template>
  <div class="w-full h-full pt-5">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <template>
        <div class="flex flex-row justify-start items-center gap-4 px-2">
          <h1 class="text-xl font-extrabold">Compensation</h1>
          <c-breadcrumb :items="breadcrumbs" />
        </div>
      </template>
      <template>
        <c-card class="px-3 py-2 mx-2 my-5">
          <c-card class="px-5 py-5 my-3 flex flex-col items-end">
            <c-button
              variant="primary btn-bg"
              label="Start New"
              class="button-style"
              @onClick="priviledgedActions(null, 'createReview')"
            />
            <Divider border-dash class="w-full mt-5" />
            <CardFooter
              reloadcard
              @reload="onGetQuery"
              @actionModal="() => {}"
              @sortType="onGetQuery({ sort: $event })"
              @searchResult="onGetQuery({ search: $event })"
            />
          </c-card>
          <template v-if="hasModuleAccess">
            <template v-if="reviews.length">
              <c-table
                :headers="headers"
                :items="reviews"
                :has-number="true"
                class="w-full"
                :loading="isFetching"
                :pagination-list="pagination"
                page-sync
                @page="onGetQuery({ page: $event })"
                @itemsPerPage="onGetQuery({ perPage: $event })"
              >
                <template v-slot:item="{ item }">
                  <span v-if="item.effectiveDate" class="font-normal text-sm text-darkPurple">
                    {{ $DATEFORMAT(new Date(item.data.effectiveDate), "MMMM dd,yyyy") }}
                  </span>
                  <span v-if="item.year" class="text-sm text-darkPurple">
                    {{ item.data.year }}
                  </span>
                  <span v-if="item.cycleName" class="text-sm text-darkPurple">
                    {{ item.data.cycleName }}
                  </span>
                  <span v-if="item.revisionRangeMinimum" class="font-normal text-sm text-darkPurple">
                    {{ item.data.revisionRangeMinimum }}% - {{ item.data.revisionRangeMaximum }}%
                  </span>
                  <div v-if="item.eligible_employees" class="font-normal text-sm text-darkPurple">
                    <span v-if="item.data.eligible_employees">
                      {{ item.data.eligible_employees.employeeIds.length }}
                    </span>
                    <span v-else>0</span>
                  </div>
                  <p v-if="item.cycle" class="font-normal text-sm text-darkPurple">
                    <span v-if="item.data.cycle">
                      {{ $DATEFORMAT(new Date(item.data.cycle.startDate), "MMM dd, yyyy") }}
                      -
                      {{ $DATEFORMAT(new Date(item.data.cycle.endDate), "MMM dd, yyyy") }}
                    </span>
                  </p>
                  <div v-if="item.initiatedBy" class="font-normal text-sm text-darkPurple">
                    <span class="text-darkPurple" v-if="Object.keys(item.data.initiatedBy).length">
                      {{ item.data.initiatedBy.fname }}
                      {{ item.data.initiatedBy.lname }}
                    </span>
                  </div>
                  <div v-if="item.status" class="text-sm capitalize whitespace-nowrap">
                    <span class="px-2 py-1 rounded-md"
                      :class="item.data.status === 'in_progress' ? 'bg-yellow-100 text-carrotOrange'
                        : item.data.status === 'disapproved' ? 'bg-red-100 text-desire'
                        : item.data.status === 'approved' ? 'bg-green-100 text-mediumSeaGreen'
                        : 'bg-gray-100 text-romanSilver'"
                    >
                      <span v-if="item.data.status">
                        {{ item.data.status.split("_").join(" ") }}
                      </span>
                      <span v-else>-</span>
                    </span>
                  </div>
                  <div v-if="item.id">
                    <c-menu left @click.stop="">
                      <template v-slot:title>
                        <Icon icon-name="more_icon" size="xs" />
                      </template>
                      <template>
                        <div class="flex flex-col w-44 h-18 p-2 justify-start items-start">
                          <template v-if="item.data.status === 'draft'">
                            <div class="hover:bg-ghostWhite w-full rounded-md cursor-pointer">
                              <div @click="priviledgedActions(item.data.id, 'editReview')">
                                <div class="flex justify-start items-center p-2 gap-2">
                                  <Icon icon-name="icon-edit" size="xs" class-name="text-blueCrayola w-10" />
                                  <span class="text-darkPurple text-sm leading-5 font-normal">Edit</span>
                                </div>
                              </div>
                            </div>
                          </template>
                          <template v-if="item.data.status === 'draft'">
                            <div class="hover:bg-ghostWhite w-full rounded-md cursor-pointer">
                              <div @click="priviledgedActions(item.data.id, 'deleteReview')">
                                <div class="flex justify-start items-center p-2 gap-2">
                                  <Icon icon-name="delete" class-name="text-flame w-10" size="xs" />
                                  <span class="text-darkPurple text-sm leading-5 font-normal">Delete</span>
                                </div>
                              </div>
                            </div>
                          </template>
                          <template v-if="item.data.status === 'in_progress' || item.data.status === 'approved'">
                            <div class="hover:bg-ghostWhite w-full rounded-md cursor-pointer">
                              <div @click="priviledgedActions(item.data.id, 'detailedReport')">
                                <div class="flex justify-start items-center p-2 gap-2">
                                  <Icon icon-name="file_todo" class-name="text-blueCrayola" size="xs" />
                                  <span class="text-darkPurple text-sm leading-5 font-normal">Detailed Report</span>
                                </div>
                              </div>
                            </div>
                          </template>
                          <div class="hover:bg-ghostWhite w-full rounded-md cursor-pointer">
                            <div @click="priviledgedActions(item.data.id, 'summaryReport')">
                              <div class="flex justify-start items-center p-2 gap-2">
                                <Icon icon-name="file_todo" class-name="text-carrotOrange" size="xs" />
                                <span class="text-darkPurple text-sm leading-5 font-normal">Summary Report</span>
                              </div>
                            </div>
                          </div>
                          <div class="hover:bg-ghostWhite w-full rounded-md">
                            <div @click="priviledgedActions(item.data, 'viewTimelines')">
                              <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                                <Icon icon-name="icon-timer" class-name="text-darkPurple -ml-1" size="s" />
                                <span class="text-darkPurple text-sm leading-5 font-normal -ml-1">Timelines</span>
                              </div>
                            </div>
                          </div>
                          <div class="hover:bg-ghostWhite w-full rounded-md" v-if="item.data.status !== 'draft'">
                            <div @click="priviledgedActions(item.data.id, 'approvalStatus')">
                              <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                                <Icon icon-name="icon-check-square" class-name="text-mediumSeaGreen" size="xs" />
                                <span class="text-darkPurple text-sm leading-5 font-normal">Approval Status</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </c-menu>
                  </div>
                </template>
              </c-table>
            </template>
            <template v-else>
              <div class="w-full flex flex-col justify-center items-center gap-5 px-10 pb-20 w-full cursor-pointer">
                <Icon size="" class-name="w-80 h-80" icon-name="comreview_illustration" />
                <p class="font-normal text-base leading-5 text-jet text-center w-3/6">
                  A list of compensation reviews for your organisaton will be shown here. Start by adding one using the button below.
                </p>
                <div @click="priviledgedActions(null, 'createReview')">
                  <div class="flex justify-start items-center gap-2">
                    <Icon size="xs" icon-name="icon-plus" />
                    <p class="font-semibold text-base leading-5 text-flame">
                      Start New Review
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <div v-else>
            <ErrorComponent />
          </div>
        </c-card>
        <ReviewTimelines
          ref="ref_timelines"
          @success="onGetQuery"
          @click-update="(reviewId) => {
            $router.push({
              name: 'UpdateCompensationReview',
              params: {id: reviewId}
            })
          }"
        />
        <ApprovalStatus ref="approval_status" />
        <SummaryReport ref="summary_report" />
        <Modal v-if="isOpenDelete">
          <c-card class="p-5 flex flex-col max-w-md">
            <div class>
              <p class="font-bold ml-4 my-5 text-center">
                Are you sure you want to delete this review?
              </p>
              <div class="flex justify-end items-center gap-2">
                <c-button
                  class="button-class-pimary btn-bg"
                  label="Delete"
                  @onClick="onDeleteReview(reviewId)"
                />
                <c-button
                  label="Cancel"
                  class="button-class-secondary btn-border"
                  @onClick="isOpenDelete = false"
                />
              </div>
            </div>
          </c-card>
        </Modal>
        <template>
          <div v-if="isOpenFilter" class="filter_table">
            <FilterTable
              v-if="isOpenFilter"
              :filter-data="filterOptions"
              @close="isOpenFilter = false"
              @submit="isOpenFilter = false"
            />
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import CBreadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb"
import CTable from "@scelloo/cloudenly-ui/src/components/table"
import CCard from "@scelloo/cloudenly-ui/src/components/card"
import CMenu from "@scelloo/cloudenly-ui/src/components/Menu"
import CButton from "@scelloo/cloudenly-ui/src/components/button"
import CardFooter from "@/components/CardFooter"
import Divider from "@/components/divider"
import Modal from "@/components/Modal"
import Icon from "@/components/Icon"
import FilterTable from "@/components/FilterTable"
import ApprovalStatus from "@/components/ApprovalStatus"
import ErrorComponent from "@/modules/Admin/error403"

export default {
  components: {
    CBreadcrumb,
    CTable,
    CCard,
    Modal,
    CButton,
    CMenu,
    Icon,
    Divider,
    CardFooter,
    FilterTable,
    ErrorComponent,
    ApprovalStatus,
    ReviewTimelines: () => import('../Modals/ReviewTimelines'),
    SummaryReport: () => import('./SummaryReport'),
  },
  data() {
    return {
      isLoading: true,
      isOpenDelete: false,
      isFetching: true,
      isOpenFilter: false,
      hasModuleAccess: false,
      reviews: [],
      reviewId: "",
      queryParams: {},
      filterOptions: [],
      pagination: { page: 1, lastPage: 1, total: 1, from: 1, to: 1},
      breadcrumbs: [
        { disabled: false, text: "Compensation", href: "compensation", id: "Compensation" },
        { disabled: false, text: "Compensation Review", href: "CompensationReview", id: "CompensationReview" }
      ],
      headers: [
        { title: "revision date", value: "effectiveDate" },
        { title: "financial year", value: "year" },
        { title: "Cycle", value: "cycleName" },
        { title: "revision range", value: "revisionRangeMinimum" },
        { title: "employee count", value: "eligible_employees" },
        { title: "Timelines", value: "cycle", width: 12 },
        { title: "initiated by", value: "initiatedBy" },
        { title: "approval Status", value: "status" },
        { title: "", value: "id", image: true }
      ]
    };
  },
  methods: {
    async onGetQuery(queryParams){
      try {
        await this.$handlePrivilege('reviewHRR', 'viewRegister')
        if(queryParams !== undefined){
          this.queryParams = {...this.queryParams, ...queryParams}
          this.getOrgCompensationReview(this.queryParams)
        } else {
          this.queryParams = {}
          this.getOrgCompensationReview()
        }
      } catch (error) {
        const message = 'You do not have permission to perform this task'
        this.$toasted.error(message, { duration: 3000 })
      }
    },
    async priviledgedActions(payload, setAction){
      try {
        if(setAction === 'createReview'){
          await this.$handlePrivilege('reviewHRR', 'startNewReview')
          this.$router.push({ name: 'CreateCompensationReview' })
        } else if(setAction === 'editReview'){
          await this.$handlePrivilege('reviewHRR', 'viewReview')
          this.$router.push({ name: 'UpdateCompensationReview', params: {id: payload} })
        } else if(setAction === 'deleteReview'){
          await this.$handlePrivilege('reviewHRR', 'deleteReview')
          this.isOpenDelete = true
          this.reviewId = payload
        } else if(setAction === 'detailedReport'){
          await this.$handlePrivilege('reviewHRR', 'viewAndUpdateDetailedReport')
          this.$router.push({ name: 'CompensationReviewDetailedReport', params: {id: payload} })
        } else if(setAction === 'summaryReport'){
          await this.$handlePrivilege('reviewHRR', 'summaryReport')
          this.$refs.summary_report.toggle(payload)
        } else if(setAction === 'viewTimelines'){
          await this.$handlePrivilege('reviewHRR', 'viewTimelines')
          this.$refs.ref_timelines.toggle(payload)
        } else if(setAction === 'approvalStatus'){
          await this.$handlePrivilege('reviewHRR', 'approvals')
          this.$refs.approval_status.toggle(payload)
        }
      } catch(error) {
        const message = 'You do not have permission to perform this task'
        this.$toasted.error(message, { duration: 3000 })
      }
    },
    onFilter(){
      this.filterOptions = [
        { header: 'Location(s)', optionsBox: this.locationOptions },
        { header: 'Function(s)', optionsBox: this.functionOptions },
        { header: 'Level(s)', optionsBox: this.levelOptions },
        { header: 'Designation(s)', optionsBox: this.designationOptions },
        { header: 'Pay Grade', optionsBox: this.paygradeOptions },
        { header: 'Performance Score', optionsBox: this.rankingOptions },
        { header: 'Gender', optionsBox: ['Male', 'Female'] },
        { header: 'Manager', optionsBox: this.managerOptions },
      ]
      this.isOpenFilter = true
    },
    async getOrgCompensationReview(params) {
      this.isFetching = true
      await this.$_getOrgCompensationReview(params).then(({ data }) => {
        this.reviews = data.data?.map((review) => ({
          ...review.review,
          initiatedBy: review.initiatedBy,
          cycleName: review.review.cycle?.cycleName
        }))
        this.isFetching = false
      }).catch((error) => {
        this.$toasted.error(error.message, {duration: 3000})
        this.isFetching = false
      })
    },
    async onDeleteReview(id) {
      this.isFetching = true
      this.isOpenDelete = false
      await this.$_deleteOrgCompensationReview(id).then(({ data }) => {
        this.reviews = this.reviews.filter($el => $el.id !== id)
        this.$toasted.success(data.message, {duration: 3000})
        this.onGetQuery()
      }).catch((error) => {
        this.$toasted.error(error.message, {duration: 3000})
        this.onGetQuery()
      })
    },
    async bootstrapModule(){
      await this.getOrgCompensationReview()
    }
  },
  async created(){
    this.isLoading = true
    try {
      await this.$handlePrivilege('reviewHRR', 'viewRegister')
      this.hasModuleAccess = true
      this.bootstrapModule()
      this.isLoading = false
    } catch (error) {
      this.hasModuleAccess = false
      this.isLoading = false
    }
  },
}
</script>

<style>
.dropdownMenu {
  margin-right: 30px !important;
}
.button-style {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  line-height: 120% !important;
  padding: 10px 40px !important;
}
.button_class {
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  justify-self: end;
}
.button-class-pimary {
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 40px !important;
}
.button-class-secondary {
  color: #321c3b !important;
  background: white !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 40px !important;
}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }

 .btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>
